<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title>
                    {{ edit ? 'Edit' : 'Add'}} Menu Category
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Title" v-model="menu.title" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.menu.title.$error">Title is required</span>

                        </v-col>
                        <v-col cols="4">

                            <v-switch v-model="menu.header" label=" Set as Header Menu">

                            </v-switch>

                        </v-col>
                        <v-col>
                            <v-btn depressed
                                   @click="createOrUpdate"
                                   class="text-white btn btn-primary">
                                {{ edit ? 'Edit' : 'Add'}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import MenuService from "@/services/cms/menu/MenuService";
    const menuService = new MenuService();
    export default {
        validations: {
            menu: {
                title: {required}
            }
        },
        data() {
            return {
                search: {
                    active: "active",
                    is_parent: 1,
                },
                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                menu: {
                    title: null,
                    header: 1,
                    is_active: true
                },
                menus: []
            };
        },
        methods: {
            getMenu() {
                menuService
                    .paginate(this.search)
                    .then(response => {
                        this.menus = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            editMenu(menu) {
                this.edit = true;
                this.menu = menu;
            },
            showModal(menu = null) {
                this.__reset();
                if (menu !== null) {
                    this.edit = true;
                    this.menu = menu;
                }

                this.dialog = true;
                this.getMenu();
                // this.$refs["my-modal"].show();
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, getMenus(id) {
                menuService.show(id).then(response => {
                    this.menu = response.data.menu
                    if (this.menu.tags) {
                        this.menu.tags = this.menu.tags.split(',');
                    }
                })
            },
            // generateFd() {
            //     // console.log(this.menu)
            //     let fd = new FormData();
            //     for (let key in this.menu) {
            //         if (key == "is_active" && this.menu["is_active"]) {
            //             fd.append("is_active", this.menu ? 1 : 0);
            //         } else {
            //             if (this.menu[key] != null) {
            //                 fd.append(key, this.menu[key]);
            //             }
            //         }
            //     }
            //     return fd;
            // },
            createOrUpdate() {
                this.$v.menu.$touch();
                if (this.$v.menu.$error) {
                    setTimeout(() => {
                        this.$v.menu.$reset();
                    }, 3000);
                } else {
                    let fd = this.menu;
                    if (this.edit) {
                        this.updateMenu(fd);
                    } else {
                        this.createMenu(fd);
                    }
                }
            },
            updateMenu(fd) {
                this.isBusy = true;
                menuService
                    .update(this.menu.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Updated successfully");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.target.files[0].size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            createMenu(fd) {
                this.isBusy = true;
                menuService
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Created successfully");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            deleteMenu(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item permanently?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            menuService
                                .delete(id)
                                .then(response => {
                                    this.getMenu();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
            __reset(){
                this.$v.menu.$reset();
                this.edit = false;
                this.menu = {
                    title: null,
                    header: 1,
                    is_active: true
                };
            },
            resetForm() {
                this.$v.menu.$reset();
                this.edit = false;
                this.menu = {
                    title: null,
                    header: 1,
                    is_active: true
                };
                this.hideModal();
                this.$emit("refresh_menu");
            }
        }
    };
</script>

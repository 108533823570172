<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <!--begin: Wizard Form-->
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-lg-6">
                                <h4>
                                    Menu Manager
                                </h4>
                                <router-link :to="{name:'dashboard'}">
                                    Dashboard
                                </router-link>
                                \
                                Menu Manager
                            </div>
                            <div class="col-md-6 col-sm-12 col-lg-6 text-right">
                                <a @click="manageCategory" class="btn btn-primary">

                                    Add Menu Category

                                </a>

                            </div>


                        </div>
                        <v-row>
                            <table class="table mt-3">
                                <thead class="text-left">
                                <th>Title</th>
                                <th>Menu Type</th>
                                <th>Action</th>
                                </thead>
                                <tr v-if="menus.length" v-for="(menu, index) of menus" :key="menu.title">
                                    <td scope="row">
                                        <router-link :to="{ name: 'menu-manager-menu' , params: {id: menu.id}}"
                                                     class="navi-link">{{menu.title}}
                                        </router-link>
                                        <i class="fas fa-circle"
                                           :class="menu.is_active?'dot-active':'dot-inactive'"></i>
                                    </td>

                                    <td>
                                        <span :class="menu.header?'badge badge-success':'badge badge-danger'">{{menu.header?'Header':'Footer'}}</span>
                                    </td>
                                    <td>
                                        <b-dropdown
                                                size="sm"
                                                variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret
                                                right
                                                no-flip
                                        >
                                            <template v-slot:button-content>
                                                <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                                            </template>
                                            <!--begin::Navigation-->
                                            <div class="navi navi-hover ">


                                                <b-dropdown-text tag="div" class="navi-item">
                                                    <a href="#" class="navi-link" @click="editMenu(menu)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                                        <span class="navi-text"> Edit </span>
                                                    </a>
                                                </b-dropdown-text>

                                                <b-dropdown-text tag="div" class="navi-item">
                                                    <a href="#" class="navi-link"
                                                       @click="deleteMenu(menu.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                                        <span class="navi-text">  Delete</span>
                                                    </a>
                                                </b-dropdown-text>

                                            </div>
                                            <!--end::Navigation-->
                                        </b-dropdown>

                                    </td>
                                </tr>

                            </table>
                        </v-row>

                    </div>
                </div>
            </div>

        </div>
        <manage-category ref="manageCategory" @refresh_menu="getMenu"></manage-category>
    </v-app>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import CreateAndUpdate from "./CreateAndUpdate";
    import draggable from 'vuedraggable'
    import {VueNestable, VueNestableHandle} from 'vue-nestable'
    import PageService from "@/services/cms/page/PageService";
    import BlogService from "@/services/cms/blog/BlogService";
    import MenuService from "@/services/cms/menu/MenuService";
    import MenuItemService from "@/services/cms/menu/item/MenuItemService";
    const pageService = new PageService();
    const blogService = new BlogService();
    const menuService = new MenuService();
    const menuItemService = new MenuItemService();
    import ManageCategory from "./ManageCategory";
    export default {
        name: "Menu",
        display: "Table",
        order: 8,
        components: {
            ManageCategory,
        },
        data() {
            return {

                menus: [],
                addMenudialog: false,
                dragging: false,
                search: {},

            };
        },
        mounted() {
            this.getMenu();

        },
        methods: {
            getMenu() {
                menuService
                    .paginate(this.search)
                    .then(response => {
                        this.menus = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            editMenu(menu) {
                this.edit = true;
                this.menu = menu;
                this.$refs['manageCategory'].showModal(this.menu);
            },


            manageCategory() {
                this.$refs['manageCategory'].showModal();
            },


            changeType(type) {
                this.newMenu.menu_type = type;
                this.newMenu.title = '';
            },
            addMenuForm() {
                this.selectedMenu = null;
            },
            opoenDialog() {
                this.addMenudialog = true;
            },
            closeDialog() {
                this.addMenudialog = false;
            },

            deleteMenu(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item permanently?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            menuService
                                .delete(id)
                                .then(response => {
                                    this.getMenu();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
            // sort() {
            //     videoGallery.sort(this.videos).then((res) => {
            //         this.$snotify.success("Sorted");
            //         this.getVideosGallery()
            //     }).catch(err => {
            //         this.$snotify.success("Sorted Error");
            //     })
            // },

        }
    };
</script>
<style scoped>
    .buttons {
        margin-top: 35px;
    }
</style>

<style lang="scss" scope>
    /*
    * Style for nestable
    */


    .nestable {
        position: relative;
    }

    .nestable-rtl {
        direction: rtl;
    }

    .nestable .nestable-list {
        margin: 0;
        padding: 0 0 0 40px;
        list-style-type: none;
    }

    .nestable-rtl .nestable-list {
        padding: 0 40px 0 0;
    }

    .nestable > .nestable-list {
        padding: 0;
    }

    .nestable-item,
    .nestable-item-copy {
        margin: 10px 0 0;
    }

    .nestable-item:first-child,
    .nestable-item-copy:first-child {
        margin-top: 0;
    }

    .nestable-item .nestable-list,
    .nestable-item-copy .nestable-list {
        margin-top: 10px;
    }

    .nestable-item {
        position: relative;
    }

    .nestable-item.is-dragging .nestable-list {
        pointer-events: none;
    }

    .nestable-item.is-dragging * {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .nestable-item.is-dragging:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(106, 127, 233, 0.274);
        border: 1px dashed rgb(73, 100, 241);
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }

    .nestable-drag-layer {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;
    }

    .nestable-rtl .nestable-drag-layer {
        left: auto;
        right: 0;
    }

    .nestable-drag-layer > .nestable-list {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        background-color: rgba(106, 127, 233, 0.274);
    }

    .nestable-rtl .nestable-drag-layer > .nestable-list {
        padding: 0;
    }

    .nestable [draggable="true"] {
        cursor: move;
    }

    .nestable-handle {
        display: inline;
    }

    .nestable-item .btn-group {
        float: right;
    }

    .nestable-item .nestable-item-content,
    .kt-portlet__body ol.nestable-list .nestable-item-content {
        color: black;
        font-weight: bold;
        padding: 1rem 2rem;
        border-radius: 5px;
        background: #eceff0;
    }

    .nestable-handle {
        margin-right: 10px;
        cursor: grabbing !important;
    }

    .nestable-handle span {
        font-weight: bold !important;
    }
</style>
